// @flow
import * as React from 'react'
import { Div, Img } from 'glamorous'

import { InfoAndImage } from 'views/components'
import bidders from './img/bidders.png'
import bidders2x from './img/bidders@2x.png'
import bidders3x from './img/bidders@3x.png'
import { breakpoints, laptop } from 'shared/media-queries'

const getWordingWidth = screenWidth => {
	if (screenWidth >= breakpoints.desktop) {
		return '431px'
	} else if (screenWidth >= breakpoints.laptop) {
		return '365px'
	}
	return '431px'
}

const Search = ({ screenWidth }: { screenWidth: number }) => (
	<InfoAndImage
		imageOnRight
		image={
			<Img
				float="right"
				width="100%"
				maxWidth="700px"
				src={bidders}
				srcSet={`${bidders2x} 2x, ${bidders3x} 3x`}
			/>
		}
		subtitle={
			<Div
				css={{
					[laptop]: { fontSize: '32px' },
				}}
			>
				Easily search by status when inviting subcontractors to bid.
			</Div>
		}
		info={
			<Div
				css={{
					[laptop]: { fontSize: '20px' },
				}}
			>
				With data flowing between our two solutions, your estimating team never
				needs to leave BuildingConnected Pro. You can even start the prequal
				process right from your BuildingConnected account.
			</Div>
		}
		wordingWidth={getWordingWidth(screenWidth)}
		spacerWidths={['120px', '50px']}
	/>
)

export default Search
