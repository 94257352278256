// @flow
import * as React from 'react'
import { Div, Img } from 'glamorous'

import { H2 } from 'views/components'
import { grayText } from 'shared/colors'
import desktop from './img/desktop.png'
import desktop2x from './img/desktop@2x.png'
import desktop3x from './img/desktop@3x.png'
import mobile from './img/mobile.png'
import mobile2x from './img/mobile@2x.png'
import mobile3x from './img/mobile@3x.png'
import { breakpoints, tablet } from 'shared/media-queries'

const BestQualifiedSubs = ({ screenWidth }: { screenWidth: number }) => (
	<Div>
		<H2
			textAlign="center"
			css={{
				[tablet]: { maxWidth: '625px', margin: '0 auto', fontSize: '32px' },
			}}
		>
			Choose the best qualified subcontractors to bid on your projects.
		</H2>
		<Div height="10px" />
		<Div
			color={grayText}
			textAlign="center"
			fontSize="24px"
			maxWidth="900px"
			margin="0 auto"
			css={{
				[tablet]: { fontSize: '20px' },
			}}
		>
			The TradeTapp and BuildingConnected Pro integration allows estimators and
			purchasing managers to see qualification decisions in real time from risk
			teams within BuildingConnected.
		</Div>
		<Div
			height="75px"
			css={{
				[tablet]: { height: '50px' },
			}}
		/>
		{screenWidth > breakpoints.tablet ? (
			<Img
				display="block"
				width="100%"
				src={desktop}
				srcSet={`${desktop2x} 2x, ${desktop3x} 3x`}
			/>
		) : (
			<Img
				display="block"
				width="100%"
				src={mobile}
				srcSet={`${mobile2x} 2x, ${mobile3x} 3x`}
			/>
		)}
	</Div>
)

export default BestQualifiedSubs
