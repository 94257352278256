// @flow
import * as React from 'react'
import { Div } from 'glamorous'

import { VerticalInfo, H2 } from 'views/components'
import { lightBlue, grayText } from 'shared/colors'
import Approved from './img/approved.svg'
import Analytics from './img/analytics.svg'
import Quals from './img/quals.svg'

const sections = [
	{
		icon: <Quals />,
		heading: '1',
		title: 'Invite subs to qualify from BuildingConnected. ',
	},
	{
		icon: <Analytics />,
		heading: '2',
		title: 'Risk team reviews and analyzes in TradeTapp.',
	},
	{
		icon: <Approved />,
		heading: '3',
		title: `Qualification results automatically appear in BuildingConnected Pro.`,
	},
]

const ReduceRisk = () => (
	<Div maxWidth="700px" margin="0 auto">
		<H2 textAlign="center">Reduce risk on your projects.</H2>
		<Div height="25px" />
		<Div textAlign="center" color={grayText}>
			Proactive risk management during the bidding process protects your bottom
			line.
		</Div>
		<Div height="50px" />
		<Div color={lightBlue} fontSize="38px" textAlign="center">
			Here’s how it works:
		</Div>
		<Div height="25px" />
		<VerticalInfo sections={sections} headingColor={lightBlue} />
	</Div>
)

export default ReduceRisk
