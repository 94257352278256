// @flow
import * as React from 'react'
import { Img } from 'glamorous'

import { ResourceHub } from 'views/components'
import videoImage from './img/video.jpg'
import videoImage2x from './img/video@2x.jpg'
import videoImage3x from './img/video@3x.jpg'
import blog1Image from './img/blog1.jpg'
import blog1Image2x from './img/blog1@2x.jpg'
import blog1Image3x from './img/blog1@3x.jpg'
import blog2Image from './img/blog2.jpg'
import blog2Image2x from './img/blog2@2x.jpg'
import blog2Image3x from './img/blog2@3x.jpg'

const cards = [
	{
		image: (
			<Img
				width="100%"
				src={videoImage}
				srcSet={`${videoImage2x} 2x, ${videoImage3x} 3x`}
			/>
		),
		title: 'Video',
		wording: `See how UAG uses TradeTapp to streamline their prequalification process.`,
		cta: 'Watch the video',
		videoId: 'htn9poynoa',
	},
	{
		image: (
			<Img
				width="100%"
				src={blog1Image}
				srcSet={`${blog1Image2x} 2x, ${blog1Image3x} 3x`}
			/>
		),
		title: 'Blog',
		wording: `Part 1: Why we integrated TradeTapp and BuildingConnected.`,
		cta: 'Read more',
		href: `https://blog.buildingconnected.com/tradetapp-and-buildingconnected-integration-part-1/`,
	},
	{
		image: (
			<Img
				width="100%"
				src={blog2Image}
				srcSet={`${blog2Image2x} 2x, ${blog2Image3x} 3x`}
			/>
		),
		title: 'Blog',
		wording: `Part 2: Why we integrated TradeTapp and BuildingConnected.`,
		cta: 'Read more',
		href: `https://blog.buildingconnected.com/tradetapp-and-buildingconnected-integration-part-2/`,
	},
]

const TradeTappResourceHub = () => (
	<ResourceHub
		title="The resource hub."
		subtitle="Learn more about TradeTapp’s risk management capabilities."
		cards={cards}
	/>
)

export default TradeTappResourceHub
