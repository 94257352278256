// @flow
import * as React from 'react'
import { Div } from 'glamorous'

import { Hero } from 'views/components'
import H1 from 'components/h1'
import HeaderBackgroundImage from './background-image'
import { breakpoints, tablet, mobile } from 'shared/media-queries'
import { lightBlue } from 'shared/colors'

// eslint-disable-next-line
const getWordingWidth = screenWidth => {
	if (screenWidth >= breakpoints.desktop) {
		return '530px'
	} else if (screenWidth >= breakpoints.laptop) {
		return '485px'
	}
	return '530px'
}

const heading = (
	<H1
		additionalCss={{
			[tablet]: { fontSize: '51px' },
			[mobile]: { fontSize: '32px' },
		}}
	>
		BuildingConnected + TradeTapp
	</H1>
)

const subHeading = (
	<Div
		fontSize="24px"
		lineHeight="1.33"
		css={{
			[mobile]: { fontSize: '18px' },
		}}
	>
		Automatically sync TradeTapp qualification data to BuildingConnected Pro, so
		you always select the right subcontractors for your projects.
	</Div>
)

const Header = ({ screenWidth }: { screenWidth: number }) => (
	<Hero
		backgroundImage={<HeaderBackgroundImage screenWidth={screenWidth} />}
		heading={heading}
		subHeading={subHeading}
		wordingWidth={getWordingWidth(screenWidth)}
		ctaColor={lightBlue}
	/>
)

export default Header
