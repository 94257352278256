// @flow
import * as React from 'react'

import Layout from 'components/layout'
import { SectionSpacer, ContentWrapper, SectionBreak } from 'views/components'
import Header from './header'
import BestQualifiedSubs from './best-qualified-subs'
import Search from './search'
import ReduceRisk from './reduce-risk'
import QuoteSection from './quote-section'
import ResourceHub from './resource-hub'
import Footer from './footer'

import useScreenWidth from 'hooks/use-screen-width'

const TradeTapp = () => {
	const screenWidth = useScreenWidth()
	if (!screenWidth) {
		return null
	}

	return (
		<Layout
			title="BuildingConnected Pro + TradeTapp Integration | BuildingConnected"
			description="Streamline subcontractor qualification from start to finish with BuildingConnected Pro and TradeTapp."
		>
			<Header screenWidth={screenWidth} />
			<SectionSpacer />
			<ContentWrapper>
				<BestQualifiedSubs screenWidth={screenWidth} />
				<SectionBreak />
				<Search screenWidth={screenWidth} />
				<SectionBreak />
				<ReduceRisk />
				<SectionBreak />
				<QuoteSection />
				<SectionBreak />
				<ResourceHub />
			</ContentWrapper>
			<SectionSpacer />
			<Footer />
		</Layout>
	)
}

export default TradeTapp
