// @flow
import * as React from 'react'
import { Div } from 'glamorous'

import { navy, lightBlue } from 'shared/colors'
import { tablet, mobile, smallerThan } from 'shared/media-queries'
import ButtonLink from 'components/button-link'

const Footer = () => (
	<Div
		height="475px"
		backgroundColor={navy}
		textAlign="center"
		display="flex"
		flexDirection="column"
		alignItems="center"
		justifyContent="center"
		padding="20px"
		css={{
			[tablet]: { height: '420px' },
			[mobile]: { height: '320px' },
			[smallerThan(425)]: {
				height: '420px',
			},
		}}
	>
		<Div
			color="white"
			maxWidth="850px"
			margin="0 auto"
			fontSize="54px"
			fontWeight="700"
			lineHeight="1.1"
			css={{
				[mobile]: { fontSize: '32px' },
			}}
		>
			Check out how TradeTapp can help reduce subcontractor risk.
		</Div>
		<Div height="50px" />
		<ButtonLink
			bgColor={lightBlue}
			href="https://www.tradetapp.com/"
			target="_blank"
			css={{ padding: '0 40px' }}
		>
			Explore TradeTapp
		</ButtonLink>
	</Div>
)

export default Footer
