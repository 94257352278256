// @flow
import * as React from 'react'

import { LargeQuote } from 'views/components'
import img from './img/headshot.png'
import img2x from './img/headshot@2x.png'
import img3x from './img/headshot@3x.png'

const QuoteSection = () => (
	<LargeQuote
		image1x={img}
		image2x={img2x}
		image3x={img3x}
		quote="Now we can consistently make smarter decisions with real data on how we’d like to select contractors and structure a bid list."
		name="TC Neuhs, Urban Atelier Group"
	/>
)

export default QuoteSection
